import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useCallback } from 'react';
import { uploadModalActions } from '../model/slice/uploadModalSlice';
import { getUploadModalState } from '../model/selectors/getUploadModalState';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';

interface HooApi {
  file: Nullable<UploadFile>;
  handleFileChange: (data: UploadChangeParam<UploadFile>) => void;
  resetFile: () => void;
}

export const useFileUpload = (): HooApi => {
  const dispatch = useAppDispatch();

  const { file } = useAppSelector(getUploadModalState);

  const handleFileChange = useCallback(
    (data: UploadChangeParam<UploadFile>): void => {
      if (data?.file) {
        dispatch(uploadModalActions.setFile(data.file));
      }
    },
    [dispatch],
  );

  const resetFile = useCallback((): void => {
    dispatch(uploadModalActions.resetFile());
  }, [dispatch]);

  return { file, handleFileChange, resetFile };
};
