import { api } from 'app/config/apiConfig';

export const sendClarificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendClarification: builder.mutation<void, string>({
      query: (email) => ({
        url: '/leads/send-clarification',
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const { useSendClarificationMutation } = sendClarificationApi;
