import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ReactComponent as UploadIcon } from 'shared/assets/icons/UploadIcon.svg';
import classNames from 'classnames';
import { MimeTypes } from 'features/UploadModal';

import s from './index.module.scss';

const { Dragger: DraggerComponent } = Upload;

interface DraggerProps {
  file?: Nullable<UploadFile>;
  title?: Nullable<string>;
  description?: Nullable<string>;
  multiple?: boolean;
  onChange?: (data: UploadChangeParam<UploadFile>) => void;
  maxCount?: number;
  allowedFormats?: MimeTypes[];
}

export const Dragger: FC<DraggerProps> = memo((props) => {
  const { file, title, description, onChange, allowedFormats, maxCount = 1, multiple = false } = props;

  const { t } = useAppTranslation('common');

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = (data: UploadChangeParam<UploadFile>): void => {
    setFileList(data.fileList);
    onChange?.(data);
  };

  const accept = useMemo(() => {
    return allowedFormats?.join(',');
  }, [allowedFormats]);

  useEffect(() => {
    if (!file) {
      setFileList([]);
    }
  }, [file]);

  return (
    <DraggerComponent
      multiple={multiple}
      accept={accept}
      fileList={fileList}
      onChange={handleChange}
      maxCount={maxCount}
      className={classNames(s.dragger)}
    >
      <div className="flex flex-col items-center">
        <UploadIcon />
        <div className="flex flex-col gap-2 mt-4">
          <div className="text-primaryLight font-normal">{title || t('Select a file or drag and drop here')}</div>
          <div className="font-normal text-xs text-primaryLight">{description}</div>
        </div>
      </div>
    </DraggerComponent>
  );
});
