import { Form } from 'antd';
import React, { FC, memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { permissionPresets, useRole, UserPermissions, UserRoles } from 'entities/User';
import { MultipleSelect } from 'shared/ui/MultipleSelect';
import { useGetAllWarehousesQuery, Warehouse } from 'entities/Warehouse';
import { ChangeUserAccess } from 'features/ChangeUserAccess';

interface AccessDetailsFormProps {
  userId: string;
  role: UserRoles;
  permissions?: UserPermissions[];
  assignedWarehouses?: Warehouse[];
}

export const AccessDetailsForm: FC<AccessDetailsFormProps> = memo((props) => {
  const { userId, role, permissions, assignedWarehouses } = props;

  const { t } = useAppTranslation(['profile', 'common']);

  const { isAdmin, isManagement, isManager } = useRole();

  const { data: warehouses } = useGetAllWarehousesQuery();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      permissions,
      assignedWarehouseIds: assignedWarehouses?.map(({ warehouseId }) => warehouseId),
    });
  }, [form, permissions, assignedWarehouses]);

  const submitButtons = (): ReactElement => {
    return (
      <ChangeUserAccess
        userId={userId}
        formInstance={form}
        action={
          <Button containerClassName="basis-1/2" size="large" isDisabled={!isAdmin}>
            {t('Change access details')}
          </Button>
        }
      />
    );
  };

  const warehouseOptions = useMemo(() => {
    return (
      warehouses?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [warehouses]);

  const permissionGroups: Record<string, UserPermissions[]> = useMemo(() => {
    return permissionPresets;
  }, []);

  const permissionOptions = [
    { label: t('Standard package'), value: 'standard' },
    { label: t('Extended package'), value: 'extended' },
    ...Object.values(UserPermissions).map((permission) => ({
      label: t(permission),
      value: permission,
    })),
  ];

  const changePermissions = useCallback(
    (selectedOptions: string[]) => {
      const permissions = selectedOptions.filter((value) => !permissionGroups[value]);

      const expandedPermissions = selectedOptions.flatMap((value) => permissionGroups[value] || value);

      const uniquePermissions = Array.from(new Set([...permissions, ...expandedPermissions]));

      form.setFieldsValue({ permissions: uniquePermissions });
    },
    [form, permissionGroups],
  );

  return (
    <div className="mt-10">
      <div className="mb-7 font-semibold text-2xl">{t('Access details')}</div>
      <Form className="space-y-2" name="accountDetailsForms" form={form} layout="vertical" disabled={!isManagement}>
        <div className="flex-col">
          <Form.Item className="w-full" name="assignedWarehouseIds" label={t('Assigned warehouses')}>
            <MultipleSelect
              placeholder={t('Please, add warehouses')}
              bordered
              options={warehouseOptions}
              allowClear
              disabled={isManager || !isAdmin}
            />
          </Form.Item>
          <Form.Item className="w-full" name="permissions" label={t(`${role} permissions`)}>
            <MultipleSelect
              placeholder={t('Please, add permissions')}
              bordered
              options={permissionOptions}
              allowClear
              disabled={isManager || !isAdmin}
              onChange={changePermissions}
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">{submitButtons()}</div>
      </Form>
    </div>
  );
});
