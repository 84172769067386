import { User } from 'entities/User';
import { Warehouse } from 'entities/Warehouse';

export interface CreateNoteData {
  isCompleted: boolean;
  noteType: NoteType;
  warehouseId?: string;
  description?: string;
  deadlineDate?: Nullable<string>;
  createdBy?: Nullable<User>;
  assignedIds?: string[];
  email?: string;
}

export enum NoteType {
  CALL = 'call',
  EMAIL = 'email',
  CHECK = 'check',
  MEETING = 'meeting',
  OVERDUE = 'overdue',
  TERMINATION = 'termination',
  OTHER = 'other',
}

export interface EditNoteData extends Partial<CreateNoteData> {
  noteId: string;
}

export interface Note {
  noteId: string;
  description: string;
  isCompleted: boolean;
  deadlineDate?: string;
  contractNumber?: string;
  createdBy?: User;
  email?: string;
  noteType: NoteType;
  warehouse?: Warehouse;
  assignedUsers?: User[];
  createdAt: string;
  updatedAt: string;
}
