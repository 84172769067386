import { Dropdown, MenuProps, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps, SelectionSelectFn, TableRowSelection } from 'antd/es/table/interface';
import { Table, TablePaginationConfig } from 'antd/lib';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { NoResults } from 'shared/ui/NoResults';
import { Select } from 'shared/ui/Select';
import { Tooltip } from 'shared/ui/Tooltip';
import { tableSort } from './tableSort';
import {
  RtkQueryPaginatedFunction,
  ServerTableActions,
  ServerTableFilter,
  ServerTableRowActions,
  ServerTableRowSelectionActions,
  TableFilterTags,
} from './types';
import { ReactComponent as SearchIcon } from 'shared/assets/icons/SearchIcon.svg';
import { ReactComponent as ContextMenuDots } from 'shared/assets/icons/ContextMenuDots.svg';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';
import s from './index.module.scss';
import { TableActionsPositions } from '../Table/types';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ContextMenu } from '../ContextMenu';
import { QueryStatus } from '@reduxjs/toolkit/query';

interface ServerTableProps {
  columns: ColumnsType<CustomAny>;
  fetchData: RtkQueryPaginatedFunction;
  title?: React.ReactNode;
  bordered?: boolean;
  rowKey: string;
  loading?: boolean;
  tableActionsPosition?: TableActionsPositions;
  search?: boolean;
  className?: string;
  filters?: ServerTableFilter[];
  tags?: TableFilterTags;
  onAllFiltersClear?: () => void;
  newElement?: CustomAny;
  tableActions?: Array<ServerTableActions<CustomAny>>;
  rowActions?: Array<ServerTableRowActions<CustomAny>>;
  rowSelectionActions?: Array<ServerTableRowSelectionActions<CustomAny>>;
  selectionType?: 'checkbox' | 'radio';
  onRowSelect?: SelectionSelectFn<CustomAny>;
  onAllRowsSelect?: (selected: boolean, selectedRows: CustomAny[], changeRows: CustomAny[]) => void;
  defaultFilters?: AnyObject;
  rowClassName?: (record: CustomAny, index: number) => string;
  applyQueryFilters?: boolean;
  scroll?: TableProps<CustomAny>['scroll'] & {
    scrollToFirstRowOnChange?: boolean;
  };
  searchPlaceholder?: string;
  rowActionsLimit?: number;
  tableActionsLimit?: number;
  onRowClick?: (data: AnyObject) => void;
  defaultSearch?: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<CustomAny>['field'];
  sortOrder?: SorterResult<CustomAny>['order'];
}

const timeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZone: 'Europe/Moscow',
};

const russianTimeFormat = new Intl.DateTimeFormat('ru-RU', timeOptions);

export type ServerTableFilterTag = Pick<ServerTableFilter, 'name'> &
  Pick<ServerTableFilter, 'type'> &
  Pick<ServerTableFilter, 'props'> &
  Record<'value', CustomAny>;

export const ServerTable: FC<ServerTableProps> = (props) => {
  const { t } = useAppTranslation('common');

  const {
    columns,
    className,
    bordered = false,
    rowKey = '',
    search = false,
    loading = false,
    fetchData,
    filters = [],
    tags,
    onAllFiltersClear,
    newElement,
    title,
    tableActions = [],
    rowActions = [],
    rowSelectionActions = [],
    selectionType = 'radio',
    onRowSelect,
    onAllRowsSelect,
    defaultFilters,
    applyQueryFilters = false,
    scroll,
    tableActionsPosition = 'post',
    searchPlaceholder = t('Search'),
    rowActionsLimit = 4,
    tableActionsLimit = 4,
    rowClassName,
    onRowClick,
    defaultSearch,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const queryFilters = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
  const [dataSource, setDataSource] = useState<AnyObject[]>([]);
  const [dynamicData, setDynamicData] = useState<Nullable<AnyObject>>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowElements, setSelectedRowElements] = useState<CustomAny[]>([]);
  const [searchText, setSearchText] = useState<Nullable<string>>(defaultSearch || null);
  const isFiltersEnabled = Boolean(filters.length);
  const [filtersObject, setFiltersObject] = useState<AnyObject>(defaultFilters || {});
  const [debouncedFiltersObject, setDebouncedFiltersObject] = useState<AnyObject>(defaultFilters || {});

  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);

  const blockSearchParamsChange = useRef<boolean>(true);

  useDebounce(
    () => {
      const filtersCopy = { ...filtersObject };
      delete filtersCopy.search;

      blockSearchParamsChange.current = false;

      setDebouncedFiltersObject(
        searchText
          ? {
              ...filtersCopy,
              search: searchText,
            }
          : filtersCopy,
      );

      setTableParams((prevState) => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            current: 1,
          },
        };
      });
    },
    600,
    [filtersObject, searchText],
  );

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} from ${total}`,
    },
  });

  const FiltersDTO = useMemo(() => {
    const copyFiltersObject = { ...debouncedFiltersObject };

    Object.keys(copyFiltersObject).forEach((key) => {
      const isFilterEmpty = copyFiltersObject[key] === undefined;
      const isNonPrimitiveFilterEmpty = _.isObject(copyFiltersObject[key]) && _.isEmpty(copyFiltersObject[key]);

      if (isFilterEmpty || isNonPrimitiveFilterEmpty) {
        delete copyFiltersObject[key];
      }
    });

    return copyFiltersObject;
  }, [debouncedFiltersObject]);

  const {
    data: dataList,
    isLoading,
    isError,
    status,
  } = fetchData(
    {
      limit: tableParams.pagination?.pageSize,
      page: tableParams.pagination?.current,
      orderBy:
        tableParams.sortField && tableParams.sortOrder ? tableSort(tableParams.sortField.toString(), tableParams.sortOrder) : undefined,
      ...FiltersDTO,
    },
    {
      skip: !(tableParams?.pagination?.pageSize || tableParams?.pagination?.current),
    },
  );

  // default filters will apply only one time

  useEffect(() => {
    if (!_.isEqual(defaultFilters, filtersObject) && defaultFilters) {
      setFiltersObject(defaultFilters);
    }
  }, [defaultFilters]); // [defaultFilters] is correct array dependency

  useEffect(() => {
    const queryFiltersObject: AnyObject = {};

    for (const key in queryFilters) {
      if (Object.prototype.hasOwnProperty.call(queryFilters, key)) {
        let element: string | string[] = queryFilters[key].split(',');

        if (element.length === 1) {
          element = queryFilters[key];
        }

        queryFiltersObject[key] = element;
      }
    }

    if (!_.isEqual(queryFiltersObject, filtersObject) && queryFiltersObject && applyQueryFilters) {
      blockSearchParamsChange.current = true;

      const multipleSelectFilters = filters.filter((item) => item.type === 'Select' && item?.props?.mode === 'multiple');

      const normalizedFilters = Object.keys(queryFiltersObject).reduce((acc: AnyObject, key: string) => {
        if (multipleSelectFilters.find((filter) => filter.name === key)) {
          const valuesArray = Array.isArray(queryFiltersObject[key]) ? queryFiltersObject[key] : [queryFiltersObject[key]];
          acc[key] = [...valuesArray];
        } else {
          acc[key] = queryFiltersObject[key];
        }
        return acc;
      }, {});

      setFiltersObject(normalizedFilters);
    }
  }, [applyQueryFilters, queryFilters]); // [applyQueryFilters, queryFilters] is correct array dependency */

  useEffect(() => {
    if (!blockSearchParamsChange.current && applyQueryFilters) {
      setSearchParams(new URLSearchParams(debouncedFiltersObject));
    }
  }, [applyQueryFilters, debouncedFiltersObject, setSearchParams]);

  useEffect(() => {
    setDynamicData(newElement ? { ...newElement, isNewDynamicElement: true } : null);
  }, [newElement]);

  useEffect(() => {
    setDataSource(dataList?.content || []);
  }, [dataList]);

  useEffect(() => {
    if (dataList?.total && dataList.total !== tableParams?.pagination?.total) {
      setTableParams((prevState) => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            total: dataList.total,
          },
        };
      });
    }
  }, [dataList, tableParams]);

  useEffect(() => {
    if (dynamicData) {
      if (tableParams?.pagination?.current === 1) {
        setIsShowWarning(false);
        setDataSource((prevData) => [dynamicData, ...[...prevData].splice(0, prevData.length - 1)]);
        setDynamicData(null);
      } else {
        setIsShowWarning(true);
      }
    }
  }, [dynamicData, tableParams?.pagination]);

  const onSearch = useCallback((value: string): void => {
    setSearchText(value || null);
  }, []);

  const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedElements: CustomAny[]): void => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowElements(selectedElements);
  }, []);

  const rowSelection: TableRowSelection<CustomAny> = {
    type: selectionType,
    onSelect: onRowSelect,
    onSelectAll: onAllRowsSelect,
    columnTitle:
      selectionType === 'checkbox'
        ? (originNode) => {
            // @ts-expect-error Origin antd checkbox props
            return <Checkbox name="selectAll" checked={originNode.props.checked} onChangeEvent={originNode.props.onChange} />;
          }
        : undefined,
    renderCell: (value, record, index, originNode) => {
      return (
        // @ts-expect-error Origin antd checkbox props
        <Checkbox name={record[rowKey]} className={originNode.props.className} checked={value} onChangeEvent={originNode.props.onChange} />
      );
    },
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const isShowHeader = useMemo(
    () => isShowWarning || (title && search) || (title && !search && !isFiltersEnabled && !tableActions && !rowSelectionActions),
    [isFiltersEnabled, isShowWarning, rowSelectionActions, search, tableActions, title],
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: SorterResult<CustomAny> | Array<SorterResult<CustomAny>>,
  ): void => {
    setTableParams((prevState) => ({
      ...prevState,
      sortField: Array.isArray(sorter) ? undefined : sorter.columnKey,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      pagination: {
        ...prevState.pagination,
        ...pagination,
      },
    }));
  };

  const clearAllFiltersWithSearch = useCallback(() => {
    onAllFiltersClear?.();

    setSearchText(null);
  }, []);

  const resetFilters = useCallback(
    (filters: ServerTableFilter[] | ServerTableFilter) => {
      const filtersCopy = { ...filtersObject };

      if (!Array.isArray(filters)) {
        filters = [filters];
      }

      filters.forEach(({ name, type }) => {
        if (filtersCopy[name]) {
          delete filtersCopy[name];
        }

        if (type === 'RangePicker') {
          const filterKeys = name.split(',');

          if (filterKeys.length === 2) {
            delete filtersCopy[filterKeys[0]];
            delete filtersCopy[filterKeys[1]];
          } else {
            delete filtersCopy.startDate;
            delete filtersCopy.endDate;
          }
        }
      });

      setFiltersObject(filtersCopy);
    },
    [filtersObject],
  );

  const filterTagsComponent = useMemo(() => {
    const filterTags = filters.reduce((result: ServerTableFilterTag[], filter) => {
      const tag = {
        type: filter.type,
        name: filter.name,
        props: filter.props,
        value: debouncedFiltersObject[filter.name],
      };

      if (debouncedFiltersObject[filter.name]) {
        return [...result, tag];
      }

      if (tag.type === 'RangePicker') {
        const filterKeys = tag.name.split(',');

        if (filterKeys.length === 2) {
          tag.value = [debouncedFiltersObject[filterKeys[0]], debouncedFiltersObject[filterKeys[1]]];
        } else {
          tag.value = [debouncedFiltersObject.startDate, debouncedFiltersObject.endDate];
        }

        if (tag.value.filter((e: string | undefined) => e).length > 0) {
          return [...result, tag];
        }
      }

      return result;
    }, []);

    return filterTags.map((filterTag) => {
      if (filterTag.type === 'Select' && filterTag.props && filterTag.props.options) {
        if (Array.isArray(filterTag.value) && filterTag.value.length > 1) {
          return (
            <Tooltip
              key={filterTag.name}
              title={
                filterTag.value
                  ? filterTag.value.reduce((acc, value) => {
                      return `${acc} ${
                        (
                          filterTag.props?.options.find((e: { value: string; label: string }) => e.value === value) || {
                            label: filterTag.value,
                          }
                        ).label
                      }, `;
                    }, '')
                  : ''
              }
            >
              <Tag
                closable
                onClose={() => {
                  resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
                }}
              >
                <div>
                  <div>
                    {`${
                      (
                        filterTag.props?.options.find((e: { value: string; label: string }) => e.value === filterTag.value[0]) || {
                          label: filterTag.value[0],
                        }
                      ).label
                    } + ${filterTag.value.length - 1}`}
                  </div>
                </div>
              </Tag>
            </Tooltip>
          );
        }

        return (
          <Tag
            key={filterTag.name}
            closable
            onClose={() => {
              resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
            }}
          >
            <div>
              <div>
                {
                  (
                    filterTag.props?.options.find(
                      (e: { value: string; label: string }) =>
                        e.value === (Array.isArray(filterTag.value) ? filterTag.value[0] : filterTag.value),
                    ) || {
                      label: Array.isArray(filterTag.value) ? filterTag.value[0] : filterTag.value,
                    }
                  ).label
                }
              </div>
            </div>
          </Tag>
        );
      }

      if (filterTag.type === 'RangePicker') {
        return (
          <Tag
            key={filterTag.name}
            closable
            onClose={() => {
              resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
            }}
          >
            <div>
              <div>
                от {russianTimeFormat.format(new Date(filterTag.value[0].replace('Z', '+00:00')))} до{' '}
                {russianTimeFormat.format(new Date(filterTag.value[1].replace('Z', '+00:00')))}
              </div>
            </div>
          </Tag>
        );
      }

      return (
        <Tag
          key={filterTag.name}
          closable
          onClose={() => {
            resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
          }}
        >
          <div>
            <div>{filterTag.value}</div>
          </div>
        </Tag>
      );
    });
  }, [debouncedFiltersObject, filters, resetFilters]);

  const renderTableActions = useCallback((): ReactNode => {
    let actions = [...tableActions.map((action) => ({ ...action, type: 'table' }))];
    let selectedElements: CustomAny[] = [];

    if (hasSelected) {
      actions = [...actions, ...rowSelectionActions.map((e) => ({ ...e, type: 'row' }))];
      selectedElements = selectedRowKeys.map((id: React.Key) => {
        return selectedRowElements.find((item: CustomAny) => item[rowKey] === id);
      });
    }

    // if (Object.keys(debouncedFiltersObject).length > 1) {
    //   actions.unshift({
    //     name: 'Clear filters',
    //     type: 'table',
    //     onClick: (actions) => {
    //       setFiltersObject({});
    //     },
    //   });
    // }

    if (actions.length > tableActionsLimit) {
      const additionalActions: MenuProps['items'] = actions.slice(tableActionsLimit).map((action, i) => {
        return {
          label: <p>{action.name}</p>,
          disabled: action.loading,
          itemIcon: action.icon,
          key: i,
          onClick: () => {
            action.onClick(action.type === 'row' ? selectedElements : dataSource);
          },
          ...action.props,
        };
      });

      return (
        <div className="flex gap-3 items-center shrink-0">
          {actions.slice(0, tableActionsLimit).map((action) => {
            return (
              <Button
                key={action.name}
                isLoading={action.loading}
                {...action.props}
                iconPosition={action.iconPosition}
                icon={action.icon}
                theme={action.theme}
                isDisabled={action.disabled}
                onClick={() => {
                  action.onClick(action.type === 'row' ? selectedElements : dataSource);
                }}
              >
                {action.name}
              </Button>
            );
          })}
          <Dropdown className="ml-3" menu={{ items: additionalActions }} trigger={['click']} placement="bottom">
            <Button icon={<ContextMenuDots />}>Additional</Button>
          </Dropdown>
        </div>
      );
    }

    return (
      <div className="flex gap-3 items-center shrink-0">
        {actions.map((action) => {
          return (
            <Button
              key={action.name}
              isLoading={action.loading}
              {...action.props}
              iconPosition={action.iconPosition}
              icon={action.icon}
              theme={action.theme}
              isDisabled={action.disabled}
              onClick={() => {
                action.onClick(action.type === 'row' ? selectedElements : dataSource);
              }}
            >
              {action.name}
            </Button>
          );
        })}
      </div>
    );
  }, [dataSource, hasSelected, rowKey, rowSelectionActions, selectedRowElements, selectedRowKeys, tableActions, tableActionsLimit]);

  const renderRowActions = useCallback(
    (item: AnyObject) => {
      if (rowActions.length > rowActionsLimit) {
        const additionalActionItems = rowActions.slice(rowActionsLimit).map((action, i) => {
          return {
            key: i,
            label: (
              <Button
                key={action.name}
                icon={action.icon}
                iconPosition="prev"
                isLoading={action.isLoading}
                isDisabled={action.isDisabled?.(item)}
                theme={action.theme}
                fontColor="primary"
                onClick={() => {
                  action.onClick(item);
                }}
              >
                {action.label}
              </Button>
            ),
          };
        });

        return (
          <div className="flex items-center justify-end space-x-7">
            {rowActions.slice(0, rowActionsLimit).map(({ onClick, name, label, icon, isLoading, isDisabled, iconPosition, theme }) => {
              return (
                <Button
                  key={name}
                  icon={icon}
                  iconPosition={iconPosition}
                  isLoading={isLoading}
                  isDisabled={isDisabled?.(item)}
                  theme={theme}
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  {label}
                </Button>
              );
            })}
            <ContextMenu items={additionalActionItems} placement="bottomLeft" />
          </div>
        );
      }

      return (
        <div className="flex items-center justify-end space-x-7">
          {rowActions.map(({ name, label, description, icon, onClick, isLoading, isDisabled, iconPosition, theme }) => {
            return (
              <Tooltip key={name} title={description || ''}>
                <Button
                  icon={icon}
                  iconPosition={iconPosition}
                  isLoading={isLoading}
                  isDisabled={isDisabled?.(item)}
                  theme={theme}
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  {label}
                </Button>
              </Tooltip>
            );
          })}
        </div>
      );
    },
    [rowActions, rowActionsLimit],
  );

  const generateFilterInputField = useCallback(
    (filter: ServerTableFilter) => {
      switch (filter.type) {
        case 'Input':
          // ONLY FOR SKLADOVKA INTERNAL LOGIC
          if (
            !(filtersObject?.targetType?.length && filtersObject.targetType.find((tag: string) => tag === 'door')) &&
            filter.name === 'targetName'
          ) {
            return null;
          }
          return (
            <div key={filter.name} className="table-filter-item">
              <Input
                onChange={(value) => {
                  setFiltersObject((prev) => ({
                    ...prev,
                    [filter.name]: value,
                  }));
                }}
                value={filtersObject[filter.name]}
                {...filter.props}
              />
            </div>
          );

        case 'Checkbox':
          return (
            <div key={filter.name} className="table-filter-item">
              <Checkbox
                name="checkbox"
                onChange={(checked) => {
                  setFiltersObject((prev) => ({
                    ...prev,
                    [filter.name]: checked,
                  }));
                }}
                checked={filtersObject[filter.name]}
                {...filter.props}
              >
                {filter.props?.label || filter.name}
              </Checkbox>
            </div>
          );

        case 'Select':
          return (
            <div key={filter.name} className="table-filter-item">
              <Select
                options={[]}
                onChange={(value) => {
                  if (value === undefined || value?.length === 0) {
                    resetFilters(filters.find(({ name }) => name === filter.name) as ServerTableFilter);
                  } else {
                    setFiltersObject((prev) => ({
                      ...prev,
                      [filter.name]: value,
                    }));
                  }
                }}
                value={filtersObject[filter.name]}
                {...filter.props}
              />
            </div>
          );

        case 'DatePicker':
          return (
            <div key={filter.name} className="table-filter-item">
              <DatePicker
                onChange={(date) => {
                  let isoDate = '';

                  if (date) {
                    isoDate = filter.props?.showTime ? date.toISOString() : `${date.toISOString().split('T')[0]}T00:00:00.000Z`;
                  }

                  setFiltersObject((prev) => ({
                    ...prev,
                    [filter.name]: isoDate,
                  }));
                }}
                {...filter.props}
                value={filtersObject[filter.name] ? dayjs(filtersObject[filter.name]) : undefined}
              />
            </div>
          );

        case 'RangePicker':
          return (
            <div key={filter.name} className="table-filter-item table-filter-item-rangepicker">
              {/* <RangePicker */}
              {/*   suffixIcon={null} */}
              {/*   onChange={(date) => { */}
              {/*     let isoDateArray: string[] | null = null; */}

              {/*     if (date?.[0] && date[1]) { */}
              {/*       isoDateArray = filter.props?.showTime */}
              {/*         ? [date[0]?.toISOString(), date[1]?.toISOString()] */}
              {/*         : [date[0]?.toISOString().split('T')[0], date[1]?.toISOString().split('T')[0]]; */}
              {/*     } */}

              {/*     const filterkeys = filter.name.split(','); */}
              {/*     const datesobject: AnyObject = {}; */}

              {/*     if (filterkeys.length === 2) { */}
              {/*       datesobject[filterkeys[0]] = isoDateArray ? isoDateArray[0] : ''; */}
              {/*       datesobject[filterkeys[1]] = isoDateArray ? isoDateArray[1] : ''; */}
              {/*     } else { */}
              {/*       datesobject.startDate = isoDateArray ? isoDateArray[0] : ''; */}
              {/*       datesobject.endDate = isoDateArray ? isoDateArray[0] : ''; */}
              {/*     } */}

              {/*     setFiltersObject((prev) => ({ */}
              {/*       ...prev, */}
              {/*       ...datesobject, */}
              {/*     })); */}
              {/*   }} */}
              {/*   value={ */}
              {/*     filtersObject[filter.name.split(',')[1]] || filtersObject.endDate */}
              {/*       ? [ */}
              {/*           dayjs(filtersObject[filter.name.split(',')[0]] || filtersObject.startDate || undefined), */}
              {/*           dayjs(filtersObject[filter.name.split(',')[1]] || filtersObject.endDate || undefined), */}
              {/*         ] */}
              {/*       : undefined */}
              {/*   } */}
              {/*   presets={[ */}
              {/*     { */}
              {/*       label: 'Сегодня', */}
              {/*       value: [dayjs().startOf('day'), dayjs().endOf('day')], */}
              {/*     }, */}
              {/*     { */}
              {/*       label: 'За последние 7 дней', */}
              {/*       value: [dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')], */}
              {/*     }, */}
              {/*     { */}
              {/*       label: 'За текущий месяц', */}
              {/*       value: [dayjs().startOf('month').startOf('day'), dayjs().endOf('day')], */}
              {/*     }, */}
              {/*   ]} */}
              {/*   {...filter.props} */}
              {/*   style={{ */}
              {/*     width: 265, */}
              {/*     ...filter.style, */}
              {/*   }} */}
              {/* /> */}
            </div>
          );

        default:
          return null;
      }
    },
    [filters, filtersObject, resetFilters],
  );

  const getColumnFilterComponent = useCallback(
    (columnName: string) => {
      const columnFilters = filters.filter((item) => item.column === columnName);

      if (columnFilters.length === 0) {
        return { filterDropdown: null };
      }

      const filtersList = columnFilters.map(generateFilterInputField);

      return {
        filterDropdown: ({ close, visible }: FilterDropdownProps) => {
          return (
            <div id={`filter-dropdown-${columnName}`} style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
              <div className="flex justify-between items-center mb-3">
                <h2>Filters</h2>
                <Button
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>
              </div>
              {filtersList}
              <div className="flex justify-end items-center">
                <Button
                  onClick={() => {
                    resetFilters(columnFilters);
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          );
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (!document.getElementById(`filter-dropdown-${columnName}`)) {
            setTimeout(() => {
              const dropdown = document.getElementById(`filter-dropdown-${columnName}`);

              dropdown?.parentElement?.classList.add('table-filter-dropdown');
            }, 0);
          }
        },
      };
    },
    [filters, generateFilterInputField, resetFilters],
  );

  const tableColumns = useMemo(() => {
    const resultColumns: ColumnsType<CustomAny> = [
      ...columns.map((column) => {
        return {
          ...column,
          ...getColumnFilterComponent(column.key as string),
        };
      }),
    ];

    if (rowActions.length) {
      resultColumns.push({
        title: '',
        key: 'actions',
        render: (_: null, item: AnyObject) => renderRowActions(item),
      });
    }

    return resultColumns;
  }, [columns, getColumnFilterComponent, renderRowActions, rowActions]);

  const renderTags = (): Nullable<ReactElement[]> => {
    if (!tags) {
      return null;
    }

    const tagElements: ReactElement[] = [];

    for (const tagKey in tags) {
      if (tags[tagKey].value != null) {
        tagElements.push(
          <div key={tagKey} className="flex items-center px-3 py-3 bg-accentLight rounded-xl">
            <div className="flex flex-col">
              <span className="text-xs text-accent">{tags[tagKey].title}</span>
              <span className="text-sm text-accent">{tags[tagKey].label}</span>
            </div>
            <CloseIcon className="ml-1.5 [&>path]:stroke-accent cursor-pointer" width={25} height={25} onClick={tags[tagKey].onDelete} />
          </div>,
        );
      }
    }

    return tagElements;
  };

  const onDividerClick = (): void => {
    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          current: 1,
        },
      };
    });
  };

  const tableLoading = loading || isLoading || status === QueryStatus.pending;

  return (
    <>
      {/* {isShowHeader && ( */}
      {/*   <Header */}
      {/*     isDividerShow={isShowWarning} */}
      {/*     onDividerClick={onDividerClick} */}
      {/*     dividerText="есть новые события" */}
      {/*     title={title} */}
      {/*     border={false} */}
      {/*   /> */}
      {/* )} */}
      <div className={classNames('table-component w-full', className)}>
        {(isFiltersEnabled || search || tableActions || rowSelectionActions) && (
          <>
            <div className="flex-shrink-0 text-2xl font-semibold mb-4">{title}</div>
            <div
              className={classNames(
                isShowHeader ? '' : 'mt-5',
                'flex flex-col mb-3.5 space-y-5 desktop:flex-row desktop:justify-between desktop:space-x-5 desktop:items-end',
              )}
            >
              {(tableActions.length || rowSelectionActions.length) && tableActionsPosition === 'prev' ? renderTableActions() : null}
              <div className="flex flex-col">
                {search && (
                  <Input
                    className="table-search-input w-[450px]"
                    placeholder={searchPlaceholder}
                    bordered
                    prefix={<SearchIcon className="stroke-accent" />}
                    onChange={onSearch}
                    value={searchText}
                  />
                )}
              </div>
              {(tableActions.length || rowSelectionActions.length) && tableActionsPosition === 'post' ? renderTableActions() : null}
            </div>
            <div className="flex items-center flex-wrap my-2 flex-1 gap-2">
              {renderTags()}
              {tags && Object.keys(tags).length > 1 && (
                <div className="text-error px-4 py-4 bg-errorLight rounded-xl cursor-pointer" onClick={clearAllFiltersWithSearch}>
                  {t('Clear All')}
                </div>
              )}
            </div>
          </>
        )}
        {!isError ? (
          <Table
            className={classNames(s.table, { 'border-solid border border-gray-200 rounded-lg': bordered })}
            columns={tableColumns}
            dataSource={dataSource}
            rowKey={(record) => record[rowKey]}
            onChange={handleTableChange}
            pagination={dataList?.content?.length ? tableParams.pagination : false}
            loading={tableLoading}
            rowSelection={rowSelectionActions.length ? rowSelection : undefined}
            scroll={scroll}
            rowClassName={rowClassName}
            onRow={(data) => ({
              onClick: () => {
                onRowClick?.(data);
              },
            })}
          />
        ) : (
          <NoResults />
        )}
      </div>
    </>
  );
};
