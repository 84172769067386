import { api } from 'app/config/apiConfig';

export const uploadWarehouseBoxesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadWarehouseBoxes: builder.mutation<void, { warehouseId: string; formData: FormData }>({
      query: (data) => ({
        url: `/warehouses/${data.warehouseId}/boxes/upload`,
        body: data.formData,
        method: 'POST',
      }),
      invalidatesTags: ['Boxes'],
    }),
  }),
});

export const { useUploadWarehouseBoxesMutation } = uploadWarehouseBoxesApi;
