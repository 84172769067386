import { Lead } from 'entities/Lead';
import { Note } from 'entities/Note';

export interface NoteDrawerSchema {
  mode: Nullable<DrawerMode>;
  note?: Nullable<Note>;
  lead?: Nullable<Lead>;
}

export enum DrawerMode {
  NOTE_EDIT = 'note_edit',
  NOTE_CREATE = 'note_create',
}
