import React, { FC, useCallback } from 'react';
import { getUploadModalState, UploadModal, uploadModalActions, useFileUpload } from 'features/UploadModal';
import { useUploadWarehouseBoxesMutation } from 'entities/Warehouse';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useAppTranslation } from 'app/config/i18Config/hooks';

export const UploadWarehouseBoxesModal: FC = () => {
  const dispatch = useAppDispatch();

  const [uploadWarehouseBoxes, { isLoading }] = useUploadWarehouseBoxesMutation();

  const { file, resetFile } = useFileUpload();

  const { t } = useAppTranslation('common');

  const { submitArgs } = useAppSelector(getUploadModalState);

  const closeModal = useCallback((): void => {
    dispatch(uploadModalActions.closeModal());

    resetFile();
  }, [dispatch, resetFile]);

  const handleUploadWarehouseBoxes = useCallback(async (): Promise<void> => {
    if (!file) {
      return;
    }

    const formData = new FormData();

    if (file.originFileObj) {
      formData.append('file', file.originFileObj);
    }

    try {
      await uploadWarehouseBoxes({ warehouseId: submitArgs?.warehouseId, formData }).unwrap();

      showNotification('info', t('Success'), t('File uploaded successfully', { ns: 'common' }));
    } catch (error: CustomAny) {
      console.log('error', error);
      const errorMessage = t(error?.data?.message || 'Error while uploading file', { ns: 'common' });

      showNotification('error', t('Error', { ns: 'common' }), errorMessage);
    } finally {
      closeModal();
    }
  }, [file, uploadWarehouseBoxes, submitArgs?.warehouseId, closeModal, t]);

  return <UploadModal onOk={handleUploadWarehouseBoxes} isLoading={isLoading} />;
};
