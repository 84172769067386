import React, { FC, memo, ReactNode, useCallback } from 'react';
import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { WarningPing } from 'shared/ui/WarningPing';
import { useFileUpload } from '../hooks/useFileUpload';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getUploadModalState } from '../model/selectors/getUploadModalState';
import { uploadModalActions } from '../model/slice/uploadModalSlice';
import { Dragger } from 'shared/ui/Dragger';

interface UploadModalProps {
  onOk: () => Promise<void>;
  isLoading?: boolean;
}

export const UploadModal: FC<UploadModalProps> = memo((props) => {
  const { onOk, isLoading } = props;

  const { isOpened, title, description, file, allowedFormats } = useAppSelector(getUploadModalState);

  const { t } = useAppTranslation('common');

  const dispatch = useAppDispatch();

  const { handleFileChange, resetFile } = useFileUpload();

  const closeModal = useCallback((): void => {
    dispatch(uploadModalActions.closeModal());

    resetFile();
  }, [dispatch, resetFile]);

  const actions = (): ReactNode => (
    <div className="flex space-x-3">
      <Button containerClassName="w-full" theme="secondary" isLoading={isLoading} onClick={closeModal}>
        {t('Cancel')}
      </Button>
      <Button containerClassName="w-full" isLoading={isLoading} onClick={onOk} isDisabled={!file}>
        {t('Upload')}
      </Button>
    </div>
  );

  return (
    <Modal className="max-w-[400px]" isOpen={isOpened} onClose={closeModal} closeIcon={false} footer={actions()}>
      <div className="mb-2">
        <WarningPing type="warning" />
      </div>
      <div className="font-semibold text-xl mb-3">{title}</div>
      <div className="text-primaryLight font-normal">{description}</div>
      <div className="my-3">
        <Dragger onChange={handleFileChange} description={description} allowedFormats={allowedFormats} file={file} />
      </div>
    </Modal>
  );
});
