import { api } from 'app/config/apiConfig';
import { ChangeUserAccessParams } from '../model/types';
import { USERS_PATH } from '../utils/constants';

export const changeUserAccessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeUserAccess: builder.mutation<void, { userId: string; params: ChangeUserAccessParams }>({
      query: ({ userId, params }) => ({
        url: `${USERS_PATH}/${userId}/access`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useChangeUserAccessMutation } = changeUserAccessApi;
